import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

interface Grid {
  columnStart: number | string;
  columnEnd: number | string;
  rowStart: number | string;
  rowEnd: number | string;
}

export const wrapper = css`
  margin: 0;
  margin-right: -1.25rem;
  grid-row: 1;
  overflow-x: hidden;
  display: grid;
  row-gap: var(--spacing-xhuge);

  @media ${breakpoints.medium} {
    margin: 0;
    margin-top: calc(var(--unit) * 25);
    overflow-x: unset;

    grid-column: 1 / -1;
    grid-row: 2;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--spacing-xxxlrg);
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

export const image = (grid: Grid) => css`
  position: relative;
  display: none;

  &:first-of-type {
    display: block;
  }

  @media ${breakpoints.medium} {
    display: block;
    grid-column: ${grid.columnStart} / ${grid.columnEnd};
    grid-row: ${grid.rowStart} / ${grid.rowEnd};
  }
`;

export const talentImageOne = css`
  position: relative;
  width: 100%;

  @media ${breakpoints.medium} {
    margin-top: ${spacing(100)};
    grid-column: 1 / 6;

    img {
      width: auto;
      height: 100%;
    }
  }
`;

export const talentImageTwo = css`
  position: relative;
  display: none;

  @media ${breakpoints.medium} {
    display: block;
    grid-column: 6 / span 7;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

export const talentImageThree = css`
  position: relative;
  display: none;

  @media ${breakpoints.medium} {
    display: block;
    grid-column: 6 / span 2;
    grid-row: span 2 / 7;
  }
`;

export const companyImageOne = css`
  position: relative;

  @media ${breakpoints.medium} {
    grid-column: 1 / 8;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

export const companyImageTwo = css`
  position: relative;
  display: none;

  @media ${breakpoints.medium} {
    display: block;
    grid-column: 8 / span 5;
    margin-top: ${spacing(104)};

    img {
      height: 100%;
      width: auto;
    }
  }
`;

export const companyImageThree = css`
  position: relative;
  display: none;

  @media ${breakpoints.medium} {
    display: block;
    grid-column: 4 / span 4;
    grid-row: 5 / span 2;
  }
`;
